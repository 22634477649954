import React from 'react';
import ErrorFallback from './ErrorFallback';
import {Link} from 'react-router-dom';

const ErrorFallbackHomePage = (e) => {
    return (
        <div>
            <Link to='/' className='link padding-bottom-2rem display-block'>Return to main page</Link>
            <ErrorFallback {...e}/>    
        </div>
    )
}

export default ErrorFallbackHomePage;