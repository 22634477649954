import React from 'react';
import {isSet} from '../../../utility';
import {adopt, checkShowCountdown} from '../../../math';
import {IN, OUT_NEGATIVE, OUT_POSITIVE, ENTITY_TYPE} from '../../../constants';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import Browser from '../../Browser';

const MiniImpl = React.memo(({now, start, end, strategy, every, entityType, gapAmount, gapUnits}) => {
    const {timeLeft, state, from, to} = React.useMemo(() => adopt(now, start, end, strategy, every), [now, start, end, strategy, every]);
    const showCountdown = React.useMemo(() => checkShowCountdown(now, state, to, from, entityType, gapAmount, gapUnits), [now, state, to, from, entityType, gapAmount, gapUnits]);

    return (
        <div
            className={classNames({
                'color-green': state === OUT_POSITIVE,
                'color-orange': state === IN || state === OUT_NEGATIVE
            })}
        >
            {(state === IN || state === OUT_POSITIVE) && showCountdown &&
            <>
            {isSet(timeLeft.days) && <>{timeLeft.days} <FormattedMessage defaultMessage='days'/>&nbsp;</>}
            {isSet(timeLeft.hours) && <>{timeLeft.hours} <FormattedMessage defaultMessage='hours'/>&nbsp;</>}
            {isSet(timeLeft.minutes) && <>{timeLeft.minutes} <FormattedMessage defaultMessage='minutes'/>&nbsp;</>}
            {isSet(timeLeft.seconds) && <>{timeLeft.seconds} <FormattedMessage defaultMessage='seconds'/>&nbsp;</>}
            </>
            }
            {state === OUT_POSITIVE && showCountdown && <><FormattedMessage defaultMessage='till opening'/></>}
            {state === OUT_POSITIVE && !showCountdown && entityType === ENTITY_TYPE.service &&
            <>
            <FormattedMessage defaultMessage='Sales opening'/>
            {`: ${from.format('DD.MM.YYYY HH:mm')}`}
            </>
            }
            {state === IN && showCountdown && isSet(timeLeft.days) && isSet(timeLeft.hours) && isSet(timeLeft.minutes) && isSet(timeLeft.seconds) &&
            <><FormattedMessage defaultMessage='till closing'/></>
            }
            {state === OUT_NEGATIVE && <><FormattedMessage defaultMessage='Closed'/></>}
        </div>
    );
});

const MiniDef = React.memo(({start, end, strategy, every, entityType, gapAmount, gapUnits}) => {
    const now = useNow();
    const nowDeferred = React.useDeferredValue(now);
    return (
        <>
            <MiniImpl {...{now: nowDeferred, start, end, strategy, every, entityType, gapAmount, gapUnits}} />
        </>
    );
});

const Mini = ({start, end, strategy, every, entityType, gapAmount, gapUnits}) => {
    if (!start && !end) return null;
    return (
        <Browser>
            <MiniDef {...{start, end, strategy, every, entityType, gapAmount, gapUnits}}/>
        </Browser>
    );
};

export default React.memo(Mini);