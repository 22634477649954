import 'isomorphic-fetch';
import Application from './components/Application';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import createRelayEnvironment from './createRelayEnvironment';
import Cookies from 'js-cookie';
import {SubscriptionStateProvider} from './components/SubscriptionState';
import {AuthorizationProvider} from './contexts/Authorization'
import CartProvider from './components/CartProvider';
import InstantProvider from './components/InstantProvider';
import CheckoutProvider from './components/CheckoutProvider';
import DialogProvider from './components/DialogProvider';
import {HelmetProvider} from 'react-helmet-async';

const store = {
    get: function(key) {return Cookies.get(key);},
    set: function(key, value) {Cookies.set(key, value, {expires: 365, path: '/'});},
    remove: function(key) {Cookies.remove(key, {path: '/'});},
};

hydrateRoot(document.getElementById('root'),
    <RelayEnvironmentProvider environment={createRelayEnvironment(
        () => ({'Content-Type': 'application/json'}), store
    )}>
        <BrowserRouter>
            <HelmetProvider>
                <AuthorizationProvider {...{store}}>
                    <SubscriptionStateProvider>
                        <CartProvider>
                            <InstantProvider>
                                <CheckoutProvider>
                                    <DialogProvider>
                                        <Application/>
                                    </DialogProvider>
                                </CheckoutProvider>
                            </InstantProvider>
                        </CartProvider>
                    </SubscriptionStateProvider>
                </AuthorizationProvider>
            </HelmetProvider>
        </BrowserRouter>
    </RelayEnvironmentProvider>
);

if (module.hot) {
    module.hot.accept();
}
