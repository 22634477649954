import React from 'react';

import './button-icon.styles.scss';

// color: primary, error
// disabled: Boolean

export const ButtonIcon = ({type, color, disabled, clickHandler, children}) => {
    const buttonProps = {
        type: type || 'button',
        className: `btn-icon btn-icon--${color || 'primary'}`,
        disabled
    };

    return (
        <button {...buttonProps}  onClick={clickHandler}>
            {children}
        </button>
    )
};

export default React.memo(ButtonIcon);