import React from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import Alert from '../../assets/icons/alert.svg';
import CheckCircle from '../../assets/icons/check-circle.svg';

import './use-form-input.scss';

// label - string or component
// fieldname - string
// register - register from useForm()
// errors - errors object from formState for fieldname
// required - boolean
// placeholder - string


const UseFormInput = ({label, register, errors, fieldname, registerOptions, placeholder, disabled, valid, type, readonly}) => {
    const required = registerOptions && registerOptions.required;

    return (
        <div className='form-row'>
            <div className={classNames('form-row__label', 'medium', 'color-gray-700', 'text-sm', {'form-row__label--required': required})}>{label}</div>
            <div className='form-row__input-container'>
                <input
                    className={classNames('form-row__input', {'form-row__input--error': errors, 'form-row__input--readonly': readonly})}
                    placeholder={placeholder}
                    {...register(fieldname, registerOptions)}
                    disabled={disabled}
                    readOnly={readonly}
                    type={type || 'string'}
                />
                {errors && <Alert className="color-error-500 form-row__input-icon" />}
                {valid && <CheckCircle className="color-success-400 form-row__input-icon" />}
                {errors && errors.type === 'required' && <div className='form-row__error text-sm color-error-500 padding-top-0dot375rem'><FormattedMessage defaultMessage='This field is required'/></div>}
                {errors && errors.type === 'pattern' && <div className='form-row__error text-sm color-error-500 padding-top-0dot375rem'><FormattedMessage defaultMessage='This field is not valid'/></div>}
                {errors && errors.message && <div className='form-row__error text-sm color-error-500 padding-top-0dot375rem'>{errors.message}</div>}
            </div>
        </div>
    )
};

export default React.memo(UseFormInput);