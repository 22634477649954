import React from 'react';
import {ErrorMessageStateContext, ErrorMessageDispatchContext} from './ErrorMessageContext';
import XLogo from '../../assets/icons/x.svg';
import AlertCircle from '../../assets/icons0/alert-circle.svg';
import './ErrorMessages.scss';
import {FormattedMessage} from 'react-intl';
import {LinkButton} from '../../components/button/button';
import ArrowNarrowRight from '../../assets/icons0/arrow-narrow-right.svg';
import {TELEGRAM_SUPPORT} from '../../constants';

export const ERRORS = {
    'windowRecaptcha': '1',
    'serverRecaptcha': '2',
    'serviceInvalid': '3',
    'wayforpay': '4',
    'fondy': '5',
    'endpointInvalid': '6',
    'technicalMerchantPools': '7',
    'serviceHasPools': '8'
};

const ErrorMessage = ({errorCode, closeError}) => {
    return (
        <div className='error-message-container padding-1rem background-color-error-25 border-1px-solid border-color-error-300 border-radius-0dot75rem position-relative' data-gtm={`error error-type-${ERRORS[errorCode] || 0}`}>
            <div className='position-absolute top-1rem left-1rem'>
                <AlertCircle className='display-block width-1dot5rem height-1dot5rem checkout-shell-alert-icon'/>
            </div>
            <div className='position-absolute top-1dot25rem right-1rem' onClick={closeError} data-gtm='error-btn close'>
                <XLogo className='display-block width-1rem height-1rem color-error-500 cursor-pointer'/>
            </div>
            <div className='color-error-700 padding-left-2dot5rem text-sm'>
                <p className='semibold padding-top-0dot125rem'>
                    <FormattedMessage defaultMessage='We are having connection problems'/>
                </p>
                <p className='color-error-600 padding-top-0dot625rem'>
                    <FormattedMessage defaultMessage='Please check your internet connection and try again.'/>
                </p>
                <div className='padding-top-0dot75rem display-flex'>
                    <p className='color-error-700 text-decoration-underline padding-right-0dot5rem'>
                        <FormattedMessage defaultMessage='Error'/>
                        {' №'}{ERRORS[errorCode] || 0}
                    </p>
                    <LinkButton {...{color: 'error', plain: true, size: 'sm', iconPosition: 'right', link: {href: TELEGRAM_SUPPORT, target: '_blank', gtm: 'error-btn support'}}}>
                        <FormattedMessage defaultMessage='Support service'/>
                        <ArrowNarrowRight className='display-block height-1dot25rem width-1dot25rem'/>
                    </LinkButton>
                </div>
            </div>
        </div>
    )
};

const ErrorMessages = React.memo(() => {
    const state = React.useContext(ErrorMessageStateContext);
    const dispatch = React.useContext(ErrorMessageDispatchContext);
    
    return (
        <div className='position-fixed top-1rem right-1rem z-index-1' data-gtm='errors-box'>
            {state.map((item) => (
                <ErrorMessage key={item.key} {...{...item}} closeError={() => dispatch({type: 'remove', payload: item.key})}/>
            ))}
        </div>
    )
});

export default React.memo(ErrorMessages);