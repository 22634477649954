import React from 'react';
import {Link} from 'react-router-dom';
import LogoLoading from '../../assets/logo-loading.svg';

import './button.scss';

// size: sm, md, lg, xl, 2xl, lg-wide, xl-wide
// mw768Size: same as size
// color: primary, secondary-gray, primary-gradient, pale, secondary-gray-dark, primary-success-gradient
// disabled: Boolean
// routerLink: {to}
// link: {target, href, rel}
// fluid: mobile, always
// iconPosition: right, left
// plain: Boolean

export const LinkButton = ({link, fluid, ...props}) => {
    return (
        <a {...link} className={`btn-link ${fluid ? `btn-link--fluid-${fluid}` : ''}`}>
            <Button fluid={fluid} {...props}/>
        </a>
    )
};

export const RouterLinkButton = ({to, fluid, ...props}) => {
    return (
        <Link to={to} className={`btn-link ${fluid ? `btn-link--fluid-${fluid}` : ''}`}>
            <Button fluid={fluid} {...props}/>
        </Link>
    )
};

const Button = ({type, color, size, disabled, clickHandler, children, fluid, iconPosition, plain, additionalClasses, isLoading, mw768Size, gtm}) => {
    const buttonProps = {
        type: type || 'button',
        className: `btn btn--${plain ? 'plain' : 'default'} btn--${color || 'primary'} btn--${size || 'm'} ${mw768Size ? `mw768-btn--${mw768Size}` : ''} ${fluid ? `btn--fluid-${fluid}` : ''} ${iconPosition ? `btn--icon-${iconPosition}` : ''} ${additionalClasses || ''}`,
        disabled
    };
    return (
        <button {...buttonProps}  onClick={clickHandler} data-gtm={gtm}>
            <div className={`${isLoading ? 'btn__content--loading' : 'btn__content'}`}>
                {children}
            </div>
            {isLoading && 
                <div className='btn__loading'>
                    <LogoLoading className='display-block'/>
                </div>
            }
        </button>
    )
};

export default React.memo(Button);