import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {queryParameters, parse} from '../qs';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from '../contexts/Authorization';

function getParameterByName(name) {
    let regex = new RegExp('[\\?&]' + name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]') + '=([^&#]*)');
    let results = regex.exec(window.location.hash);
    if (results === null)
        return null;
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function Callback() {
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    const navigate = useNavigate();
    const navigateRef = React.useRef();
    navigateRef.current = navigate;
    React.useEffect(() => {
        authorizationStore.set(tokenType__, getParameterByName(tokenType__));
        authorizationStore.set(accessToken__, getParameterByName(accessToken__));
        authorizationStore.set(refreshToken__, getParameterByName(refreshToken__));
        authorizationDispatch();
        const qps = queryParameters(locationRef.current);
        navigateRef.current(
            parse(locationRef.current, 'continue') + (
                qps.length ?
                    ((parse(locationRef.current, 'continue').includes('?') ? '&' : '?') + qps.join('&'))
                    :
                    ''
            ),
            {replace: true}
        );
    }, [navigateRef, locationRef]);
    return (
        <div>
            Loading...
        </div>
    );
}

export default React.memo(Callback);