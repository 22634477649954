import React from 'react';
import './Shell.scss';
import {Outlet, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ArrowRightSvg from '../../../assets/icons/arrow-right.svg';
// import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.png';
import ShoppingBag03Svg from '../../../assets/icons0/shopping-bag-03.svg';
import classNames from 'classnames';
import LogIn04Svg from '../../../assets/icons0/log-in-04.svg';
import {CartStateContext} from '../../CartContext';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {queryParameters} from '../../../qs';
import searchUnFilter from '../../../searchUnFilter';
import Button from '../../button/button';
import Footer from './Footer';
import Avatar120120Png from '../../../assets/avatar-120-120@3x.png';
import Wallet01Svg from '../../../assets/icons0/wallet-01.svg';
import Building02Svg from '../../../assets/icons0/building-02.svg';
import LogOut04Svg from '../../../assets/icons0/log-out-04.svg';
import SuspenseList from '../../../components/SuspenseList';
import Header from './Header';
import ErrorFallback from '../../ErrorFallback';
import Browser from '../../Browser';
import {graphqlWhoamiNextQuery} from '../../graphql';
import {useAuthorizationState} from '../../../contexts/Authorization';
import SignIn from '../../sign-in';

const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';

export const ShoppingBag = React.memo(() => {
    const {payload} = useParams();
    const location = useLocation();
    const state = React.useContext(CartStateContext);
    const quantity = React.useMemo(() => state.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0), [state]);
    return (
        <Link to={`/${payload}/checkout-new/cart${searchFilter(location.search)}`}>
            <div className='height-2dot5rem width-2dot5rem display-flex justify-content-center align-items-center position-relative'>
                <ShoppingBag03Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                {!!quantity &&
                    <Browser>
                        <div className='position-absolute color-white background-color-gray-600 border-radius-50percent text-xs medium text-align-center' style={{top: 2, left: 20, width: '18px', height: '18px'}}>{quantity}</div>
                    </Browser>
                }
            </div>
        </Link>
    );
});

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
export const User = React.memo(() => {
    const {payload} = useParams();
    const authorized = useAuthorizationState();
    const location = useLocation();
    const qps = queryParameters(location);
    const [flag, setFlag] = React.useState();
    const onClick = () => setFlag(true);
    return (
        <>
        {flag && <div className='display-none'><SignIn/></div>}
        <div className='hide-small margin-left-1rem'>
            {authorized ?
                <Link to={`/${payload}/sign-out${searchFilter(location.search)}`}>
                    <img className='display-block height-2dot5rem width-2dot5rem cursor-pointer' src={Avatar120120Png} alt=''/>
                </Link>
                :
                <div className='display-flex align-items-center'>
                    <Button 
                        color='secondary-gray' 
                        clickHandler={onClick} 
                        iconPosition='left' size='md'
                    >
                        <LogIn04Svg className='display-block height-1dot25rem width-1dot25rem color-gray-700'/>
                        <FormattedMessage defaultMessage='Sign in'/>
                    </Button>
                </div>
            }
        </div>
        </>
    );
});

const Shell = () => {
    const location = useLocation();
    return (
        <>
        <div className='background-color-gray-800'>
            <div className='view'>
                <div className='block'>
                    <div className='min-height-2rem display-flex justify-content-center-end'>
                        <a 
                            href={formLink} 
                            target='_blank' 
                            rel='noopener noreferrer'
                            className='display-flex align-items-center text-sm medium color-gray-25'
                        >
                            <FormattedMessage defaultMessage='Use Ottry in your business'/>
                            <ArrowRightSvg className='margin-left-0dot5rem color-gray-25 height-1rem width-1rem'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Header/>
        <SuspenseList revealOrder='forwards'>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                {/* ToDo: should the shell have React.Suspense wrapper for outlet? SuspenseList? */}
                {/* <React.Suspense fallback={'Loading...'}> */}
                    <Outlet/>
                {/* </React.Suspense> */}
            </ErrorBoundary>
            <Footer/>
        </SuspenseList>
        </>
    );
};

export default React.memo(Shell);