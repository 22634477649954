import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {useIntl, FormattedMessage} from 'react-intl';
import UseFormInput from '../../../components/use-form-input/use-form-input';
import UseFormAddlInput from '../../../components/use-form-addl-input/use-form-addl-input';
import {length} from '../../../utility';
import {get} from '../../../fh';
import {useAuthorizationState} from '../../../contexts/Authorization';

const UserContactForm = ({serviceId, registerFormField, formState, valid}) => {
    const intl = useIntl();
    const {errors} = formState;
    const authorized = useAuthorizationState();
    const {serviceById} = useLazyLoadQuery(
        graphql`
            query UserContactFormImpulseCheckoutPagesQuery($id: String) {
                serviceById(id: $id) {
                    id
                    addls {
                        id
                        ...useFormAddlInputClose_addl
                    }
                }
            }
        `,
        {id: serviceId}
    );
    return (
        <div>
            {!authorized &&
                <UseFormInput {...{
                    label: <FormattedMessage defaultMessage='Email'/>,
                    fieldname: 'email',
                    register: registerFormField,
                    errors: errors.email,
                    valid,
                    type: 'email',
                    placeholder: 'me@example.com',
                    registerOptions: {
                        required: true,
                        pattern: /^\S+@\S+\.\S+$/,
                        setValueAs: v => v ? v.trim() : v,
                        validate: {
                            checkDomain: async (v) => {
                                const [_, domainPart] = v.split('@');
                                try {
                                    const response = await get(`/api/domain?domain=${domainPart}`);
                                    const json = await response.json();
                                    if (!json) {
                                        return intl.formatMessage({defaultMessage: 'This domain is not valid'});
                                    }
                                    return json;
                                } catch (response) {
                                    return intl.formatMessage({defaultMessage: 'This domain is not valid'});
                                }
                            }
                        }
                    }
                }}/>
            }
            {length(serviceById.addls) &&
                serviceById.addls.map(e => <UseFormAddlInput {...{key: e.id, register: registerFormField, addl: e, error: errors.addls && errors.addls[e.id], valid}}/>)
            }
        </div>
    )
};

export default React.memo(UserContactForm);
