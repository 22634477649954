/**
 * @generated SignedSource<<419ce7b97538a96b8f805481b201f783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DEndpoint_serviceById",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Service",
  "abstractKey": null
};

node.hash = "ef591771afd5d95591e0f7b51a79a4ac";

module.exports = node;
