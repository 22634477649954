import React from 'react';
import classNames from 'classnames';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl'
import {CartStateContext, CartDispatchContext} from '../../../components/CartContext';
import {ErrorBoundary} from 'react-error-boundary';
import SuspenseList from '../../../components/SuspenseList';
import Skeleton from '../../../components/Skeleton';
import Footer from './Footer';
import OrderItem from './OrderItem';
import PopUp from '../../../components/pop-up/pop-up.js';
import Button from '../../../components/button/button';

import './Order.scss';
import ErrorFallback from '../../../components/ErrorFallback';

const Order = () => {
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [popUpVisible, setPopUpVisibility] = React.useState(false);
    const [popUpConfirmAction, setPopUpConfirmAction] = React.useState(null);
    const deleteItemAction = React.useCallback((confirmAction) => {
        setPopUpConfirmAction(confirmAction);
        setPopUpVisibility(true);
    });

    const request = React.useMemo(() => ({
        picks: state.map(({endpointId, serviceId}) => ({
            endpoint: endpointId,
            service: serviceId,
            quantity: 1
        }))
    }), [state]);

    const footerRequest = React.useMemo(() => ({
        picks: state.map(({endpointId, serviceId, quantity, untie}) => ({
            endpoint: endpointId,
            service: serviceId,
            quantity,
            ...(untie && {untie: {
                currency: untie.currency,
                price: untie.price
            }})
        }))
    }), [state]);
    const footerRequestDeferred = React.useDeferredValue(footerRequest);


    const popupConfirm = () => {
        dispatch(popUpConfirmAction);
        setPopUpConfirmAction(null);
        setPopUpVisibility(false);
    }

    const popupCancel = () => {
        setPopUpConfirmAction(null);
        setPopUpVisibility(false);
    }

    React.useEffect(() => {
        if (!data.landImpulse.lands[activeIndex]) {
            setActiveIndex(data.landImpulse.lands.length - 1);
        }
    }, [request]);

    const data = useLazyLoadQuery(
        graphql`
            query OrderCartPagesDeferredQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                }
            }
        `,
        {request}
    );
    
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const leftSwipeAction = () => {
        if (touchStart - touchEnd > 150) {
            const newIndex = activeIndex + 1 === state.length ? activeIndex : activeIndex + 1;
            setActiveIndex(newIndex);
        }
    }

    const rightSwipeAction= () => {
        if (touchStart - touchEnd < -150) {
            const newIndex = activeIndex - 1 < 0 ? activeIndex : activeIndex - 1;
            setActiveIndex(newIndex);
        }
    }

    const handleTouchEnd = () => {
        leftSwipeAction();
        rightSwipeAction();
    }

    return (
        <div className={classNames('order flex-1 display-flex position-relative', {'order--several': state.length > 1})}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {data.landImpulse.lands.length > 1 && 
                <div className='order__list-nav'>
                    {data.landImpulse.lands.map((x, i) =>
                        <div key={i} className='order__list-nav-item' onClick={() => setActiveIndex(i)}>
                            <div className={classNames('order__list-nav-bar', {'background-color-primary-600': i === activeIndex, 'background-color-primary-100': i !== activeIndex})}></div>
                        </div>
                    )}
                </div>
            }
            <div className='order__list display-flex flex-direction-column-row flex-1'>
                <SuspenseList revealOrder='forwards'>
                    {data.landImpulse.lands.map((land, index) =>
                        <SuspenseList key={index}>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <React.Suspense fallback={
                                    <div className={classNames('flex-1', {'display-none': activeIndex !== index})}>
                                        <Skeleton/>
                                    </div>
                                }>
                                    <div className={classNames('padding-bottom-2rem display-flex flex-direction-column order__items-container', {'display-none': activeIndex !== index})}>
                                        {land.pickExs.map((item, i) =>
                                            state[item.index] && <OrderItem {...{key: state[item.index].key, entry: state[item.index], deleteItemAction: deleteItemAction}}/>
                                        )}
                                    </div>
                                    <React.Suspense fallback={
                                        <div className={classNames('flex-1', {'display-none': activeIndex !== index})}>
                                            <Skeleton/>
                                        </div>
                                    }>
                                        <div className={classNames('footer-container', {'display-none': activeIndex !== index})}>
                                            <Footer {...{index, request: footerRequestDeferred, pendingRequest: footerRequest !== footerRequestDeferred}}/>
                                        </div>
                                    </React.Suspense>
                                </React.Suspense>
                            </ErrorBoundary>
                        </SuspenseList>
                    )}
                </SuspenseList>
            </div>
            <PopUp {...{mode: 'default', isVisible: popUpVisible, icon: 'alert', title: <FormattedMessage defaultMessage='Remove the ticket from the basket'/>, message: <FormattedMessage defaultMessage='Are you sure you want to remove this ticket from your cart?'/>}}>
                <div className='flex-1'>
                    <Button {...{size: 'lg', color: 'error', fluid: 'always', clickHandler: popupConfirm}}>
                        <FormattedMessage defaultMessage='Remove'/>
                    </Button>
                </div>
                <div className='flex-1 mw768-margin-right-0dot75rem'>
                    <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: popupCancel}}>
                        <FormattedMessage defaultMessage='Return'/>
                    </Button>
                </div>
            </PopUp>
        </div>
    )
};

export default React.memo(Order);