import React from 'react';
import {FormattedMessage} from 'react-intl';
import {InstantStateContext, InstantDispatchContext} from '../../../components/InstantContext';
import ImpulsePage from '../impulse/Impulse';

const VendingImpulsePage = () => {
    const state = React.useContext(InstantStateContext);
    const dispatch = React.useContext(InstantDispatchContext);
    const message = <FormattedMessage defaultMessage='A payment check will be sent to the specified e-mail address'/>;
    const showOrderItemQuantity = false;

return (
    <ImpulsePage {...{state, dispatch, message, showOrderItemQuantity}}/>
)
};

export default React.memo(VendingImpulsePage);
