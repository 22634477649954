export default (price) => {
    // graphql Endpoint type has price property as BigDecimal
    const value = typeof(price) === 'number' ? price.toString() : price;
    if (price) {
        const splitPrice = value.split('.');
        const whole = splitPrice[0];
        const fraction = splitPrice[1];
        return {whole, fraction}
    } else {
        return {whole: '', fraction: ''}
    }
};