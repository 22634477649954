import React from 'react';
import './launch.scss';
import './toolset.scss';
import Origin from './theme/Origin';
import Ogle from './theme/Ogle';
import {ErrorBoundary} from 'react-error-boundary';
import Warp from './root/warp/Warp';
import Callback from './Callback';
import SignOut from './sign-out';
import CheckoutRouting from '../pages/checkout/Routing';
import CookieConsent from './cookie-consent';
import {Routes, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import Simple from './Simple';
import {FetchKeyProvider} from './FetchKey';
import {IntlProvider} from 'react-intl';
import useQuery from '../useQuery';
import en from '../../compiled-lang/en';
import uk from '../../compiled-lang/uk';
import {Outlet} from 'react-router-dom';
import ErrorFallback from './ErrorFallback';
import ScrollState from './ScrollState';

const locales = {en, uk};

const Application = () => {
    return (
        <>
            <Helmet>
                <title>Welcome to i0</title>
                <meta name='description' content='Web site of your dream'/>
                <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png'/>
                <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
                <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png'/>
                <link rel='manifest' href='/site.webmanifest'/>
                <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#1570ef'/>
                <meta name='msapplication-TileColor' content='#da532c'/>
                <meta name='theme-color' content='#ffffff'/>
            </Helmet>
            <ScrollState/>
            <CookieConsent/>
            <Routes>
                <Route element={<Origin><Outlet/></Origin>}>
                    <Route path=':payload' element={<Warp/>}/>
                </Route>
                <Route path=':payload/checkout-new/*'>
                    <Route path='*' element={<Ogle><CheckoutRouting/></Ogle>}/>
                </Route>
                <Route path=':payload/sign-out' element={<Origin><SignOut/></Origin>}/>
                <Route path='callback' element={<Origin><Callback/></Origin>}/>
            </Routes>
        </>
    );
};

const IntlProviderUrlConnected = React.memo(({children}) => {
    const {locale = 'uk'} = useQuery();
    return (
        <IntlProvider locale={locale} defaultLocale='en' messages={locales[locale]}>
            {children}
        </IntlProvider>
    );
});

export default React.memo(() => (
    <FetchKeyProvider>
        <IntlProviderUrlConnected>
            <ErrorBoundary  {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={'Loading...'}>
                    <Application/>
                </React.Suspense>
            </ErrorBoundary>
        </IntlProviderUrlConnected>
    </FetchKeyProvider>
));
