import React from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import './Shell.scss';
import searchFilter from '../../../searchFilter';
import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.svg';
import Xsvg from '../../../assets/icons/x.svg';
import ChevronDownSvg from '../../../assets/icons/chevron-down.svg';
import ChevronUpSvg from '../../../assets/icons/chevron-up.svg';
import QrCodeScan01Svg from '../../../assets/icons0/qr-code-scan-01.svg';
import Menu01Svg from '../../../assets/icons0/menu-01.svg';
import {ShoppingBag, User} from './Shell';
import {TELEGRAM_SUPPORT} from '../../../constants';
import {useAuthorizationState} from '../../../contexts/Authorization';

const Header = () => {
    const {payload} = useParams();
    const authorized = useAuthorizationState();
    const location = useLocation();
    return (
        <>
            <div className='border-bottom-1px-solid border-bottom-color-gray-100'>
                <div className='view'>
                    <div className='padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem'>
                        <div className='height-4dot5rem display-flex justify-content-space-between align-items-center'>
                            <div className='display-flex'>
                                <Link to={`/${payload}${searchFilter(location.search)}`}>
                                    <LogoDarkModeFalseSvg className='display-block width-6dot125rem height-2dot375rem'/>
                                </Link>
                            </div>
                            <div className='display-flex align-items-center'>
                                <ShoppingBag/>
                                <User/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default React.memo(Header);