import React from 'react';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import useQuery from '../../../useQuery';
import UseFormInput from '../../../components/use-form-input/use-form-input';
import i18n from '../../../i18n';

const UserInfo = ({email, addls}) => {
    const {locale = 'uk'} = useQuery();
    const {register, setValue} = useForm({ defaultValues: {
        email: email
    }});

    const addlsMap = !addls ? [] : addls.map((item, index) => {
        setValue(`${index}`, item.value);
        return {
            ...item,
            id: index
        }
    });

    return (
        <>
            <UseFormInput {...{label: <FormattedMessage defaultMessage="Email"/>, fieldname: 'email', register, readonly: true, valid: true}}/>
            {addlsMap.length !== 0 &&
                addlsMap.map(addl => <UseFormInput {...{key: addl.id, label: i18n(addl, 'name', locale), fieldname: `${addl.id}`, register, readonly: true, valid: true}}/>)
            }
        </>
    )
}

export default React.memo(UserInfo);