import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import useQuery from '../../../useQuery';
import i18n from '../../../i18n';
import splitPrice from '../splitPrice';

export const OrderItemRequestData = ({entity, ...props}) => {
    const {endpointById, serviceById} = useLazyLoadQuery(
        graphql`
            query OrderItemImpulseCheckoutPagesQuery($endpointId: String, $serviceId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    nameUk
                    price
                }
                serviceById(id: $serviceId) {
                    id
                    name
                    nameUk
                    target
                }
            }
        `,
        {endpointId: entity.endpointId, serviceId: entity.serviceId}
    ); 
    return (
        <OrderItem {...{service: serviceById, untie: entity.untie, quantity: entity.quantity, ...endpointById, ...props}}  />
    )
};

const OrderItem = ({service, name, price, untie, quantity, nameUk, showOrderItemQuantity}) => {
    const {locale = 'uk'} = useQuery();
    const splittedPrice = splitPrice(untie ? untie.price : price);

    return (
        <>
            <div className='checkout-order-item display-flex padding-top-1rem padding-bottom-1rem'>
                {showOrderItemQuantity && <p className='text-md color-blue-light-600 semibold padding-right-0dot625rem'>{quantity}x</p>}
                <div className='flex-1 padding-right-1rem'>
                    <p className='text-md color-gray-700'>{i18n(service, 'name', locale)} | {i18n({name, nameUk}, 'name', locale)}</p>
                    {moment(service.target).isValid() && <p className='text-md color-gray-500 margin-top-0dot25rem'>{moment(service.target).format('DD.MM.YYYY')}</p>}
                </div>
                <p className='text-md color-gray-900'>
                    <span>
                        <span>{splittedPrice.whole}</span>
                        {splittedPrice.fraction &&
                            <>
                                <span>.</span>
                                <span className='text-xs medium'>{splittedPrice.fraction}</span>
                            </>
                        }
                        <span>{' '}</span>
                    </span>
                    <FormattedMessage defaultMessage='UAH'/>
                </p>
            </div>
            <div className='divider border-bottom-color-gray-200'/>
        </>
    )
};

export default React.memo(OrderItem);
