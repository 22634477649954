import React from 'react';

const ErrorFallback = (e) => {
    return (
        <div>
            <h1>Application Error</h1>
            <pre style={{whiteSpace: 'pre-wrap'}}>{e.error.toString()}</pre>
        </div>
    );
};

export default React.memo(ErrorFallback);
