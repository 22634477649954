import React from 'react';
import './ogle.scss';
import {ErrorBoundary} from 'react-error-boundary';
import AuthorizationError from '../AuthorizationError';

const Ogle = ({children}) => {
    return (
        <div className='ogle'>
            <ErrorBoundary {...{FallbackComponent: AuthorizationError}}>
                {children}
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(Ogle);