import qs from 'qs';

const filterFunc = (prefix, value) => {
    const filter = ['locale', 'currency', 'apply'];
    if (filter.includes(prefix)) return;
    return value;
};

export default (search) => {
    const current = qs.parse(search, {ignoreQueryPrefix: true});
    return qs.stringify(
        {
            ...current
        },
        {
            filter: filterFunc,
            addQueryPrefix: true
        }
    );
};