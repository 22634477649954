import React from 'react';
import {InstantStateContext, InstantDispatchContext} from './InstantContext';
import usePageVisibility from '../usePageVisibility';
import {v4} from 'uuid';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{quantity: 1, key: v4(), ...action.payload}];
        case 'bulkRemove':
            return state.filter((entry) => !action.payload.includes(entry.key));
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const instant__ = 'instant__0';
const deserialize = () => window.localStorage.getItem(instant__) && JSON.parse(window.localStorage.getItem(instant__)) || [];
const serialize = (instant) => window.localStorage.setItem(instant__, JSON.stringify(instant));

export default React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible)
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <InstantStateContext.Provider value={state}>
            <InstantDispatchContext.Provider value={dispatch}>
                {children}
            </InstantDispatchContext.Provider>
        </InstantStateContext.Provider>
    );
});