import React from 'react';
import {ErrorMessageStateContext, ErrorMessageDispatchContext} from './ErrorMessageContext';

import {v4} from 'uuid';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{key: v4(), ...action.payload}, ...state];
        case 'remove':
            return state.filter((entry) => entry.key !== action.payload);
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

export default React.memo(({children}) => {
    const [state, setState] = React.useState([]);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            return nextState;
        });
    }, []);
    return (
        <ErrorMessageStateContext.Provider value={state}>
            <ErrorMessageDispatchContext.Provider value={dispatch}>
                {children}
            </ErrorMessageDispatchContext.Provider>
        </ErrorMessageStateContext.Provider>
    );
});