/**
 * @generated SignedSource<<5c2a6564e5381f6c83b6b92f6ff1f58b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CEndpoint_serviceById",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Service",
  "abstractKey": null
};

node.hash = "70277e280fa40adc52afa4693e4c6251";

module.exports = node;
