import React from 'react';
import {useLocation} from 'react-router-dom';

const store = {
    get: function(field) {return this[field];},
    set: function(field, value) {this[field] = value}
};

const ScrollState = () => {
    const location = useLocation();
    React.useEffect(() => {
        window.history.scrollRestoration = 'manual';
        return () => {
            window.history.scrollRestoration = 'auto';
        };
    }, []);
    React.useEffect(() => {
        const handle = () => {
            store.set(location.key, window.pageYOffset);
        };
        window.addEventListener('scroll', handle);
        return () => {
            window.removeEventListener('scroll', handle);
        };
    }, [location.key]);
    React.useEffect(() => {
        const scrollPosition = store.get(location.key);
        if (scrollPosition) {
            window.scrollTo(0, scrollPosition);
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.key]);
    return null;
}

export default React.memo(ScrollState);