import React from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../components/Skeleton';
import {SUPPORT_CONTACT} from '../../constants';
import useQuery from '../../useQuery';
import LogoWhiteIconDarkModeFalse from '../../assets/logo-white-icon-dark-mode-false.svg';
import LogoDark from '../../assets/logo-dark.svg';
import PopUp from '../../components/pop-up/pop-up.js';
import Button, {RouterLinkButton} from '../../components/button/button';
import searchFilter from '../../searchFilter';
import ErrorMessageProvider from './ErrorMessageProvider';
import './Shell.scss';
import ErrorMessages from './ErrorMessages';
import ErrorFallback from '../../components/ErrorFallback';

const Header = ({showWarning}) => {
    return (
        <div className='checkout-shell-header'>
            <div className='view'>
                <div className='block'>
                    <LogoWhiteIconDarkModeFalse className='display-block margin-top-0dot25rem margin-bottom-0dot25rem' onClick={() => showWarning()}/>
                </div>
            </div>
        </div>
    )
};

const Footer = ({showWarning}) => {
    const {locale = 'uk'} = useQuery();

    return (
        <div className='checkout-shell-footer padding-bottom-3rem'>
            <div className='view'>
                <div className='block'>
                    <div className='padding-top-2dot5rem mw768-padding-top-0 checkout-shell-footer__block'>
                        <LogoDark className='display-block margin-0-auto margin-bottom-2dot5rem mw768-margin-bottom-2rem' onClick={() => showWarning()} />
                        <a className='checkout-shell-footer__call text-align-center text-md color-gray-700 display-block margin-bottom-2rem mw768-margin-bottom-3rem' href={`tel:${SUPPORT_CONTACT.cell}`}>
                            {SUPPORT_CONTACT.cellFormatted}
                        </a>
                    </div>
                    <div className='checkout-shell-footer__legal display-flex mw768-padding-top-2rem'>
                        <div className='display-flex padding-bottom-1rem mw768-padding-bottom-0'>
                            <div><a className='text-sm color-gray-400' href={`/legal/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a></div>
                            <div className='margin-left-1rem'><a className='text-sm color-gray-400' href={`/legal/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a></div>
                            <div className='margin-left-1rem'><a className='text-sm color-gray-400' href={`/legal/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a></div>
                        </div>
                        <div className='text-align-center text-xs color-gray-400'>
                            <FormattedMessage defaultMessage='This site is protected by Google reCAPTCHA,'/>
                            {' '}
                            <a className='color-gray-600' href={`/legal/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                            {' '}
                            <FormattedMessage defaultMessage='and' />
                            {' '}
                            <a className='color-gray-600' href={`/legal/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const CheckoutShell = () => {
    const {payload} = useParams();
    const [popUpVisible, setPopUpVisibility] = React.useState(false);
    const location = useLocation();

    const showWarning = React.useCallback(() => {
        setPopUpVisibility(true)
    }, []);
    
    return (
        <div className='display-flex flex-direction-column checkout'>
            <ErrorMessageProvider>
                <Header showWarning={showWarning}/>
                <div className='flex-1 display-flex flex-direction-column'>
                    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                        <React.Suspense fallback={
                            <div className='checkout-content block width-100percent margin-bottom-2rem'>
                                <div className='padding-top-2rem checkout-view display-flex flex-direction-column checkout-view--container'>
                                    <Skeleton/>
                                </div>
                            </div>
                        }>
                                <Outlet/>
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
                <div>
                    <Footer showWarning={showWarning}/>
                </div>
                <PopUp {...{mode: 'default', isVisible: popUpVisible, icon: 'alert', title: <FormattedMessage defaultMessage='Cancel ordering?'/>, message: <FormattedMessage defaultMessage='You have not completed your order yet.'/>}}>
                    <div className='flex-1'>
                        <RouterLinkButton {...{to: `/${payload}${searchFilter(location.search)}`, size: 'lg', color: 'error', fluid: 'always'}}>
                            <FormattedMessage defaultMessage='Yes, cancel'/>
                        </RouterLinkButton>
                    </div>
                    <div className='flex-1 mw768-margin-right-0dot75rem'>
                        <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: () => setPopUpVisibility(false)}}>
                            <FormattedMessage defaultMessage='Return'/>
                        </Button>
                    </div>
                </PopUp>
                <div className='position-absolute'>
                    <ErrorMessages/>
                </div>
            </ErrorMessageProvider>
        </div>
    )
};

export default React.memo(CheckoutShell);