import React from 'react';
import {useNavigate} from 'react-router-dom';
import Skeleton from './Skeleton';

const EffectRedirect = ({to, replace, state, relative}) => {
    const navigate = useNavigate();
    React.useEffect(() => {
        navigate(to, {replace, state, relative});
    }, []);
    return (
        <Skeleton/>
    );
};

export default React.memo(EffectRedirect);

