import Cookies from 'js-cookie';

const fh = async (method, url, bar) => {
    const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
    let headers = {};
    if (Cookies.get('tokenType') && Cookies.get('accessToken')) {
        headers = {...headers, ...{'Authorization': Cookies.get('tokenType') + ' ' + Cookies.get('accessToken')}};
    }
    const response = await fetch(`${RAZZLE_APP_PREFIX}${url}`, {headers, method, body: bar});
    if (!response.ok) {
        throw response; // Not in 200-299 response cpdes
    }
    return response;
};

export const upload = async (url, bar) => {
    return await fh('POST', url, bar);
};

export const get = async (url, bar) => {
    return await fh('GET', url, bar);
};