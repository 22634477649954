import React from 'react';
import {DialogStateContext, DialogDispatchContext} from './DialogContext';
import usePageVisibility from '../usePageVisibility';
import {v4} from 'uuid';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{key: v4(), ...action.payload}, ...state];
        case 'remove':
            return state.filter((entry) => entry.key !== action.payload);
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const dialog__ = 'dialog__0';
const deserialize = () => window.localStorage.getItem(dialog__) && JSON.parse(window.localStorage.getItem(dialog__)) || [];
const serialize = (dialog) => window.localStorage.setItem(dialog__, JSON.stringify(dialog));

export default React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible)
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <DialogStateContext.Provider value={state}>
            <DialogDispatchContext.Provider value={dispatch}>
                {children}
            </DialogDispatchContext.Provider>
        </DialogStateContext.Provider>
    );
});