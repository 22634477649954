import React from 'react';
import './origin.scss';
import {ErrorBoundary} from 'react-error-boundary';
import AuthorizationError from '../AuthorizationError';

const Origin = ({children}) => {
    return (
        <div className='origin'>
            <ErrorBoundary {...{FallbackComponent: AuthorizationError}}>
                {children}
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(Origin);