import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';
import useQuery from '../../../useQuery';
import searchFilter from '../../../searchFilter';
import {ErrorBoundary} from 'react-error-boundary';
import {RouterLinkButton} from '../../../components/button/button';
import {CheckoutStateContext} from '../../../components/CheckoutContext';
import Impulse from './Impulse';
import Skeleton from '../../../components/Skeleton';
import ErrorFallback from '../../../components/ErrorFallback';

const PaymentPage = ({successVerticalPath, subtitle, orderMessage, showOrderItemQuantity}) => {
    const {payload} = useParams();
    const location = useLocation();
    const {impulseId} = useQuery();
    const state = React.useContext(CheckoutStateContext);
    const checkoutImpulse = React.useMemo(() => state.find(e => e.id === impulseId), [state, impulseId]);

    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={
                    <div className='checkout-content block width-100percent margin-bottom-2rem'>
                        <div className='padding-top-2rem checkout-view display-flex flex-direction-column checkout-view--container'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                <div className='checkout-content block payment'>
                    <div className='checkout-view display-flex flex-direction-column checkout-view--container'>
                        {checkoutImpulse && <Impulse {...{claim: checkoutImpulse.claim, successVerticalPath, subtitle, orderMessage, showOrderItemQuantity}}/>}
                        {!checkoutImpulse && 
                            <div className='padding-top-14rem padding-bottom-10rem mw768-padding-left-5rem mw768-padding-right-5rem'>
                                <div className='text-xl color-gray-700 semibold text-align-center'>
                                    <FormattedMessage defaultMessage="We couldn't find your order"/>
                                </div>
                                <div className='text-lg color-gray-500 padding-bottom-1dot5rem text-align-center'>
                                    <FormattedMessage defaultMessage="Find out what's interesting"/>
                                </div>
                                <div className='text-align-center'>
                                    <RouterLinkButton {...{color: 'primary-gradient', size: 'xl', fluid: 'mobile', to: `/${payload}${searchFilter(location.search)}`}}>
                                        <FormattedMessage defaultMessage='Return to main page'/>
                                    </RouterLinkButton>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </React.Suspense>
        </ErrorBoundary>
    )
};

export default React.memo(PaymentPage);
