import React from 'react';
import {FormattedMessage} from 'react-intl';
import {CartStateContext} from '../../../components/CartContext';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../components/Skeleton';
import EmptyCart from './EmptyCart';
import Order from './Order';
import ErrorFallback from '../../../components/ErrorFallback';

const CartPage = () => {
    const state = React.useContext(CartStateContext);

    return (
       <div className='background-color-gray-50 flex-1 display-flex flex-direction-column'>
            <div className='view mw768-padding-bottom-4rem padding-top-2rem display-flex flex-1 width-100percent'>
                <div className='block display-flex flex-1 flex-direction-column'>
                    <p className='text-header semibold color-gray-900 padding-bottom-2rem mw768-padding-bottom-2dot5rem'>
                        <FormattedMessage defaultMessage='Cart'/>
                    </p>
                    {state.length === 0 && 
                        <EmptyCart/>
                    }
                    {!!state.length && 
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={
                                <div>
                                    <Skeleton/>
                                </div>
                            }>
                                <Order/>
                            </React.Suspense>
                        </ErrorBoundary>
                    }
                </div>
            </div>
       </div>
    )
};

export default React.memo(CartPage);