import React from 'react';
import './Marked.scss';
import ReactMarkdown from 'react-markdown';
import SuspenseImageDynamicSize from './SuspenseImageDynamicSize';
import {ErrorBoundary} from 'react-error-boundary';
import directive from 'remark-directive';
import visit from 'unist-util-visit';
import ErrorFallback from './ErrorFallback';

const SuspenseImageWithErrorBoundary = React.memo((props) =>
    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
        <SuspenseImageDynamicSize {...props}/>
    </ErrorBoundary>
);

// https://github.com/remarkjs/remark-directive
// https://github.com/micromark/micromark-extension-directive#syntax
// https://codesandbox.io/s/sv7de?file=/src/customDirectives.js
// https://www.ryanfiller.com/blog/remark-and-rehype-plugins
const ResponsiveIframe = React.memo((props) =>
    <div className='container' style={{paddingTop: props.ratio}}>
        <iframe className='responsive-iframe' {...props}/>
    </div>
);
const RemarkIframePlugin = () => (tree) => {
    visit(tree, (node) => {
        if (node.type === 'textDirective' || node.type === 'leafDirective' || node.type === 'containerDirective') {
            if (node.name !== 'youtube') return;
            const attributes = node.attributes || {};
            if (!attributes.src || !attributes.width || !attributes.height) return;
            const data = node.data || (node.data = {});
            data.hName = 'iframe';
            data.hProperties = {
                src: attributes.src,
                ratio: `${attributes.height / attributes.width * 100}%`,
                frameBorder: 0,
                allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                allowFullScreen: true
            }
        }
    });
};

const Marked = React.memo(({content}) => {
    if (!content) return null;
    return (
        <div className='marked overflow-wrap-break-word'>
            <ReactMarkdown
                components={{image: SuspenseImageWithErrorBoundary, iframe: ResponsiveIframe}}
                remarkPlugins={[directive, RemarkIframePlugin]}
            >{content}</ReactMarkdown>
        </div>
    );
});

export default Marked;