import React from 'react';
import useHydrated from '../useHydrated';

const Browser = ({children, fallback}) => {
    const hydrated = useHydrated();
    return (
        <>
            {hydrated ? <>{children}</> : (fallback ? <>{fallback}</> : <></>)}
        </>
    );
}

export default React.memo(Browser);