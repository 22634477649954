import React from 'react';
import {graphql, useFragment} from 'react-relay/hooks';

import i18n from '../../i18n';
import UseFormInput from '../use-form-input/use-form-input';
import UseFormTextarea from '../use-form-textarea/use-form-textarea';
import UseFormCheckbox from '../use-form-checkbox/use-form-checkbox';
import useQuery from '../../useQuery';

const UseFormAddlInput = ({addl, error, register, disabled, valid, readonly}) => {
    const {locale = 'uk'} = useQuery();
    const fAddl = useFragment(
        graphql`
            fragment useFormAddlInputClose_addl on Addl {
                id
                name
                nameUk
                placeholder
                placeholderUk
                regex
                message
                messageUk
                isStar
                empty
                emptyUk
                type
            }
        `,
        addl
    );
    const required = fAddl.isStar;

    return (
        <>
            {fAddl.type === 0 && 
                <UseFormInput
                    label={i18n(fAddl, 'name', locale)}
                    fieldname={`addls.${fAddl.id}`}
                    register={register}
                    registerOptions={{
                        required,
                        ...(fAddl.regex && {pattern: new RegExp(fAddl.regex)})
                    }}
                    placeholder={i18n(fAddl, 'placeholder', locale)}
                    errors={error}
                    disabled={disabled}
                    readonly={readonly}
                    valid={valid}
                    type={fAddl.id === '5bf82306b0a8223b878489e4' ? 'tel' : 'string'}
                />
            }
            {fAddl.type === 2 && 
                <UseFormTextarea
                    label={i18n(fAddl, 'name', locale)}
                    fieldname={`addls.${fAddl.id}`}
                    register={register}
                    registerOptions={{
                        required,
                        ...(fAddl.regex && {pattern: new RegExp(fAddl.regex)})
                    }}
                    placeholder={i18n(fAddl, 'placeholder', locale)}
                    errors={error}
                    disabled={disabled}
                    readonly={readonly}
                    valid={valid}
                    rows='5'
                />
            }
            {fAddl.type === 3 && 
                <UseFormCheckbox
                    label={i18n(fAddl, 'name', locale)}
                    fieldname={`addls.${fAddl.id}`}
                    register={register}
                />
            }
        </>
    )

};

export default React.memo(UseFormAddlInput);