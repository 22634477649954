import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation, useRelayEnvironment, fetchQuery} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import useQuery from '../../../useQuery';
import searchFilter from '../../../searchFilter';
import useMountedState from '../../../useMountedState';
import {ErrorBoundary} from 'react-error-boundary';
import Button, {RouterLinkButton} from '../../../components/button/button';
import {CheckoutDispatchContext} from '../../../components/CheckoutContext';
import {DialogDispatchContext} from '../../../components/DialogContext';
import Order from './Order';
import {OrderItemRequestData} from './OrderItem';
import UserDetails from './UserDetails';
import NewUserDetails from './NewUserDetails';
import MailDark from '../../../assets/icons/mail-dark.svg';
import Skeleton from '../../../components/Skeleton';
import classNames from 'classnames';
import {isNullOrUndefined, isSet} from '../../../utility';
import {ErrorMessageDispatchContext} from '../ErrorMessageContext';
import ErrorFallback from '../../../components/ErrorFallback';
import {useAuthorizationState} from '../../../contexts/Authorization';
import EffectRedirect from '../../../components/EffectRedirect';

const legal = 'https://www.ottry.com/legal';
const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_TECHNICAL_MERCHANT = process.env.RAZZLE_TECHNICAL_MERCHANT;

const ImpulsePage = React.memo(({
    state, dispatch, subtitle, message, showOrderItemQuantity, request, flag, setCode, isError, 
    setIsError, newImpulseId, setNewImpulseId}) => {
    const {payload} = useParams();
    const location = useLocation();
    const {landId = 0, locale = 'uk'} = useQuery();
    const authorized = useAuthorizationState();
    const {register, handleSubmit, formState, setValue} = useForm();
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const checkoutDispatch = React.useContext(CheckoutDispatchContext);
    const dialogDispatch = React.useContext(DialogDispatchContext);
    const errorDispatch = React.useContext(ErrorMessageDispatchContext);
    const [isPending, startTransition] = React.useTransition({timeoutMs: 5000});
    const requestData = useLazyLoadQuery(
        graphql`
            query ImpulseCheckoutPagesQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        service
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                        amountAmount
                        feeAmount
                        serviceFeeAmount
                        basis
                        discountAmount
                        coupon {
                            code
                            discount
                        }
                    }
                }
            }
        `,
        request.variables,
        request.options
    );
    const land = requestData.landImpulse.lands[landId];
    const picks = React.useMemo(
        () =>  land ? land.pickExs.map(e => state[e.index]) : [],
        [state, land]
    );
    const serviceId = land && land.service;
    const amount = land && requestData.landImpulse.solves[landId].amount;
    const amountAmount = land && requestData.landImpulse.solves[landId].amountAmount;
    const serviceFee = land && requestData.landImpulse.solves[landId].serviceFeeAmount;
    const coupon = land && requestData.landImpulse.solves[landId].coupon;
    const basis = land && requestData.landImpulse.solves[landId].basis;
    const discountAmount = land && requestData.landImpulse.solves[landId].discountAmount;
    const {serviceById} = useLazyLoadQuery(
        graphql`
            query ImpulseCheckoutPagesServiceQuery($serviceId: String) {
                serviceById(id: $serviceId) {
                    id
                    mrchntd
                    pools {
                        id
                    }
                }
            }
        `,
        {serviceId: serviceId}
    );
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation ImpulseCheckoutPagesMutation($impulseRequest: ImpulseRequestInput!) {
                createImpulse(impulseRequest: $impulseRequest) {
                    id
                    orderDates
                    amount
                    impulseService {
                        service {
                            id
                            name
                        }
                        independenceType
                        merchantAccount
                        partnerCode
                        merchantDomainName
                        holdTimeout
                        manual
                        mrchntd
                    }
                    impulseEndpoints {
                        name
                        price
                        quantity
                    }
                    impulseClient {
                        email
                    }
                    feeAmount
                    amountAmount
                    orderReferences
                    merchantSignatures
                    claim
                }
            }
    `);
    const createImpulse = handleSubmit(formValue => {
        errorDispatch({type: 'reset', payload: []});
        if (!window.grecaptcha) {
            errorDispatch({type: 'add', payload: {errorCode: 'windowRecaptcha'}});
            return;
        }
        if (serviceById.mrchntd === RAZZLE_TECHNICAL_MERCHANT && (!serviceById.pools || (serviceById.pools && !serviceById.pools.length))) {
            errorDispatch({type: 'add', payload: {errorCode: 'technicalMerchantPools'}});
            return;
        }
        if (serviceById.mrchntd !== RAZZLE_TECHNICAL_MERCHANT && serviceById.pools && serviceById.pools.length) {
            errorDispatch({type: 'add', payload: {errorCode: 'serviceHasPools'}});
            return;
        }
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                commit({
                    variables: {
                        impulseRequest: {
                            locale,
                            email: formValue.email,
                            reCaptcha: token,
                            ...(coupon && {impulseCoupon: {code: coupon.code}}),
                            service: serviceId,
                            impulseEndpoints: picks.map((item) => ({
                                endpoint: item.endpointId,
                                quantity: item.quantity,
                                ...(item.untie && {untie: {
                                    currency: item.untie.currency,
                                    price: item.untie.price
                                }})
                            })),
                            impulseAddls: formValue.addls && Object.entries(formValue.addls)
                                .map(([key, value]) => ({
                                    addl: key,
                                    value: value === true ? '+' : value === false ? '-' : value
                                }))
                        }
                    },
                    onCompleted: (data) => {
                        startTransition(() => {
                            checkoutDispatch({type: 'add', payload: {id: data.createImpulse.id, claim: data.createImpulse.claim}});
                            data.createImpulse.impulseService.manual &&
                                dialogDispatch({type: 'add', payload: {impulseId: data.createImpulse.id, claim: data.createImpulse.claim}});
                            dispatch({type: 'bulkRemove', payload: picks.map(item => item.key)});
                            setNewImpulseId(data.createImpulse.id);
                        })
                    },
                    onError: (error) => {
                        if (/reCaptcha/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serverRecaptcha'}});
                        } else if (/Service is invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serviceInvalid'}});
                        } else if (/Endpoint invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'endpointInvalid'}});
                        } else {
                            errorDispatch({type: 'add', payload: {errorCode: null}});
                        }
                    },
                    updater: store => {
                        const latestImpulses = store.get('client:root:viewer:__LatestImpulses_impulsesByViewer_connection(sent:true,status:[3,4])');
                        latestImpulses && latestImpulses.invalidateRecord();
                    }
                });
                setGrecaptcha(false);
            });
        });
    });

    if (newImpulseId) {
        return <EffectRedirect to={`../payment${searchFilter(location.search, {impulseId: newImpulseId})}`} replace/>;
    }

    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='checkout-content block width-100percent margin-bottom-2rem'>
                        <div className='checkout-view display-flex flex-direction-column checkout-view--container padding-top-2rem'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <div className='checkout-content block impulse'>
                        <div className='checkout-view display-flex flex-direction-column checkout-view--container'>
                            {land && <>
                                <div className='checkout-content__shadow-block block-width padding-top-2rem mw768-padding-top-2dot5rem z-index-1'>
                                    <p className='text-header semibold color-gray-900'>
                                        <FormattedMessage defaultMessage='Ordering'/>
                                    </p>
                                    {subtitle &&
                                        <p className='text-md color-gray-500 padding-top-0dot75rem'>
                                            {subtitle}
                                        </p>
                                    }
                                    <Order {...{total: amount, amount: amountAmount, serviceFee, coupon, setCode, isError, setIsError, basis, discountAmount}}>
                                        {picks.map((item, index) =>
                                            <OrderItemRequestData {...{key: index, entity: item, showOrderItemQuantity}}/>
                                        )}
                                    </Order>
                                </div>
                                <div className='checkout-content__message display-flex block-width align-items-center justify-content-center background-color-gray-50'>
                                    <div>
                                        <MailDark className='display-block'/>
                                    </div>
                                    {message && <p className='text-md color-gray-600 padding-left-0dot75rem'>{message}</p>}
                                </div>
                                {serviceId && 
                                    <div className="padding-bottom-2rem">
                                        <p className="text-2xl semibold color-gray-900 padding-top-2rem mw768-padding-top-2dot5rem">
                                            <FormattedMessage defaultMessage='Contact details'/>
                                        </p>
                                        {authorized ?
                                            <UserDetails {...{serviceId, registerFormField: register, formState, setValue}}/>
                                            :
                                            <NewUserDetails {...{serviceId, registerFormField: register, formState}}/>
                                        }
                                    </div>
                                }
                                <div className='checkout-footer padding-bottom-3dot5rem mw768-padding-bottom-7dot5rem'>
                                    <Button {...{fluid: 'always', color: 'primary-gradient', size: 'lg', clickHandler: () => !grecaptcha && !isMutationInFlight && createImpulse(), isLoading: grecaptcha || isMutationInFlight}}> 
                                        <FormattedMessage defaultMessage='Proceed with ordering'/>
                                    </Button>
                                    <div className='padding-top-1dot25rem color-gray-400 text-xs'>
                                        <FormattedMessage defaultMessage='By clicking Proceed button, you agree to the'/>
                                        {' '}
                                        <a className='color-gray-500 text-decoration-underline' href={legal + `/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a>
                                        {' '}
                                        <FormattedMessage defaultMessage='and' />
                                        {' '}
                                        <a className='color-gray-500 text-decoration-underline' href={legal + `/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a>
                                        {' '}
                                        <FormattedMessage defaultMessage='and that you have read the' />
                                        {' '}
                                        <a className='color-gray-500 text-decoration-underline' href={legal + `/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                                    </div>
                                </div>
                            </>}
                            {!land &&
                                <div className='padding-top-14rem padding-bottom-10rem mw768-padding-left-5rem mw768-padding-right-5rem'>
                                    <div className='text-xl color-gray-700 semibold text-align-center'>
                                        <FormattedMessage defaultMessage="We couldn't find your order"/>
                                    </div>
                                    <div className='text-lg color-gray-500 padding-bottom-1dot5rem text-align-center'>
                                        <FormattedMessage defaultMessage="Find out what's interesting"/>
                                    </div>
                                    <div className='text-align-center'>
                                        <RouterLinkButton {...{color: 'primary-gradient', size: 'xl', fluid: 'mobile', to: `/${payload}${searchFilter(location.search)}`}} >
                                            <FormattedMessage defaultMessage='Return to main page'/>
                                        </RouterLinkButton>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    )
});

const getPicksByState = (state) => state.map(({endpointId, serviceId, quantity, untie}) => ({
    endpoint: endpointId,
    service: serviceId,
    quantity: quantity,
    ...(untie && {untie: {
        currency: untie.currency,
        price: untie.price
    }})
}));

export default React.memo((props) => {
    const {landId = 0} = useQuery();
    const [newImpulseId, setNewImpulseId] = React.useState(null);
    const [code, setCode] = React.useState();
    const {state} = props;
    const [queryArgs, setQueryArgs] = useMountedState({
        options: {fetchKey: 0, fetchPolicy: 'store-or-network'},
        variables: {request: {picks: getPicksByState(state)}},
    });
    const isMount = React.useRef(true);
    React.useEffect(() => {
        if (isMount.current) {
            isMount.current = false;
        } else {
            if (isNullOrUndefined(newImpulseId) && isNullOrUndefined(code)) {
                setQueryArgs(prev => ({
                    options: {
                        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
                        fetchPolicy: 'store-or-network',
                    },
                    variables: {request: {picks: getPicksByState(state)}}
                }));
            }
        }
    }, [state, code]);
    const queryArgsDeferred = React.useDeferredValue(queryArgs);
    const [isRefreshing, setIsRefreshing] = useMountedState();
    const [isError, setIsError] = useMountedState();
    const environment = useRelayEnvironment();
    const refetch = React.useCallback(() => {
        if (isRefreshing) 
            return;
        setIsRefreshing(true);
        setIsError(false);
        const variables = {request: {picks: getPicksByState(state), landMetas: [{index: landId, code}]}};
        fetchQuery(
            environment,  
            graphql`
                query ImpulseRefetchCheckoutPagesQuery($request: LandRequestInput) {
                    landImpulse(request: $request) {
                        lands {
                            service
                            pickExs {
                                index
                            }
                        }
                        solves {
                            amount
                            amountAmount
                            feeAmount
                            serviceFeeAmount
                            basis
                            discountAmount
                            coupon {
                                code
                                discount
                            }
                        }
                    }
                }
            `, 
            variables)
        .subscribe({
            complete: () => {
                setIsRefreshing(false);
                setIsError(false);
                setQueryArgs(prev => ({
                    options: {
                        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
                        fetchPolicy: 'store-only',
                    },
                    variables
                }));
            },
            error: () => {
                setIsRefreshing(false);
                setIsError(true);
            }
        });
      }, [isRefreshing, setIsRefreshing, environment, setQueryArgs, setIsError, landId, state, code]);
    React.useEffect(() => {
        if (isNullOrUndefined(newImpulseId) && isSet(code)) {
            refetch();
        }
    }, [state, code, newImpulseId]);
    return (
        <ImpulsePage {...{
            ...props,
            request: queryArgsDeferred,
            flag: queryArgs !== queryArgsDeferred || isRefreshing,
            setCode,
            isError,
            setIsError,
            newImpulseId, 
            setNewImpulseId
        }}/>
    );
});

