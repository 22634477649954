import React from 'react';
import {FormattedMessage} from 'react-intl';
import Popover from '../../../components/popover/popover';
import InformationCircle from '../../../assets/icons/information-circle.svg';
import './order.scss';
import XCloseSvg from '../../../assets/icons0/x-close.svg';
import CornerDownLeftSvg from '../../../assets/icons0/corner-down-left.svg';
import classNames from 'classnames';
import splitPrice from '../splitPrice';
import {useForm} from 'react-hook-form';
import {isNullOrUndefined, isSet} from '../../../utility';

const Coupon = React.memo(({coupon, setCode, isError, setIsError}) => {
    const [flag, setFlag] = React.useState();
    const {register, handleSubmit, formState: {errors}, reset, setFocus, setError} = useForm();
    const submit = handleSubmit(data => setCode(data.input));
    React.useEffect(() => {
        flag && setFocus('input');
    }, [setFocus, flag]);
    React.useEffect(() => {
        isError && setError('input', {type: 'custom'});
    }, [setError, isError]);
    return (
        <div className='coupon'>
            <div className='form'>
                <div className={classNames({
                    'padding-top-1dot25rem padding-bottom-1dot25rem': isSet(coupon) || setCode
                })}>
                    <div className='display-flex align-items-center justify-content-space-between'>
                        {isNullOrUndefined(coupon) ?
                        <>
                        {setCode &&
                        <>
                        <div className='text-sm medium color-gray-700 height-2dot5rem display-flex align-items-center'><FormattedMessage defaultMessage='Coupon'/></div>
                        <div 
                            style={{boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'}}
                            className={classNames('border-radius-0dot5rem padding-top-0dot5rem padding-bottom-0dot5rem display-flex justify-content-center align-items-center cursor-pointer border-1px-solid border-color-gray-300 padding-left-0dot875rem padding-right-0dot875rem', {
                                'display-none': flag
                            })}
                            onClick={() => {
                                setFlag((flag) => !flag);
                                reset();
                            }}
                        >
                            <div className='text-sm medium color-gray-700'><FormattedMessage defaultMessage='Add'/></div>
                        </div>
                        <div
                            className={classNames('padding-0dot5rem cursor-pointer', {
                                'display-none': !flag
                            })}
                            onClick={() => {
                                setFlag((flag) => !flag);
                                setCode();
                                setIsError();
                            }}
                        >
                           <XCloseSvg className='display-block height-1dot25rem width-1dot25rem color-gray-500'/>
                        </div>
                        </>
                        }
                        </>
                        :
                        <>
                        <div className='text-sm medium color-gray-700 height-2dot5rem display-flex align-items-center'><FormattedMessage defaultMessage='Coupon'/></div>
                        <div className='display-flex padding-0dot25rem border-radius-1rem background-color-indigo-50'>
                            <div className='text-sm medium color-indigo-700 padding-left-0dot5rem'>
                                -{coupon.discount}%
                            </div>
                            {setCode &&
                            <div 
                                style={{boxShadow: '0px 1px 2px 0px #1018281A'}}
                                className='cursor-pointer width-1dot25rem height-1dot25rem background-color-white border-radius-1rem margin-left-0dot5rem display-flex justify-content-center align-items-center'
                                onClick={() => {
                                    setFlag((flag) => !flag);
                                    setCode();
                                }}
                            >
                                <XCloseSvg className='display-block height-0dot75rem width-0dot75rem color-indigo-500'/>
                            </div>
                            }
                        </div>
                        </>
                        }
                    </div>
                    {flag && isNullOrUndefined(coupon) &&
                    <div className='padding-top-1rem display-flex justify-content-space-between'>
                        <div className='flex-1 padding-right-0dot75rem'>
                            <form onSubmit={submit}>
                                <div
                                    className={classNames('border-1px-solid border-color-gray-300 border-radius-0dot5rem input', {'errors': errors.input})}
                                >
                                    <div className='flex-1 padding-left-0dot875rem padding-top-0dot625rem padding-bottom-0dot625rem'>
                                        <input
                                            className='width-100percent text-md color-gray-500'
                                            placeholder='Промокод'
                                            {...register('input', {required: true})}
                                        />
                                    </div>
                                </div>
                                {errors.input  && errors.input.type === 'required' &&  <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is required'/></div>}
                                {errors.input  && errors.input.type === 'custom' && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='Coupon is invalid'/></div>}
                            </form>
                        </div>
                        <div>
                            <div
                                style={{
                                    background: 'linear-gradient(90deg, #475467 0%, #667085 100%)',
                                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                                }}
                                className='border-1px-solid border-color-transparent border-radius-0dot5rem padding-0dot75rem cursor-pointer'
                                onClick={() => submit()}
                            >
                                <CornerDownLeftSvg className='display-block height-1dot25rem width-1dot25rem color-white'/>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                {(isSet(coupon) || setCode) &&
                <div className='border-bottom-1px-dashed border-bottom-color-gray-300'/>
                }
            </div>
        </div>
    );
});

const PopoverContent = ({orderAmount, serviceFee, total}) => {
    return (
        <div className='text-xs'>
            {/* <p>
                <FormattedMessage defaultMessage='Order'/><span>{` - ${serviceFee ? orderAmount : total} `}</span><FormattedMessage defaultMessage='UAH'/>
            </p> */}
            <p><FormattedMessage defaultMessage='Services fee'/><span>{` - ${serviceFee || 0} `}</span><FormattedMessage defaultMessage='UAH'/></p>
        </div>
    )
}

const Order = ({total, serviceFee, amount, children, coupon, setCode, isError, setIsError, basis, discountAmount}) => {
    const splittedTotal = splitPrice(total);
    return (
        <div className='order-container padding-top-2rem'>
            <div className='divider border-bottom-color-gray-200'/>
            {children}
            <Coupon {...{coupon, setCode, isError, setIsError}}/>
            <div className='padding-top-1dot5rem padding-bottom-1rem'>
                {(isSet(serviceFee) || isSet(coupon)) &&
                <div className='padding-bottom-1rem'>
                    <div className='display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Sum'/></div>
                        <div className='text-sm color-gray-500'>{basis} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    {isSet(serviceFee) && 
                    <div className='padding-top-0dot5rem display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Services fee'/></div>
                        <div className='text-sm color-gray-500'>{serviceFee} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    }
                    {(isSet(coupon)) &&
                    <div className='padding-top-0dot5rem display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Coupon discount'/></div>
                        <div className='text-sm color-gray-500'>-{discountAmount} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    }
                </div>
                }
                <div className='display-flex'>
                    <p className='text-lg semibold color-gray-700 flex-1 padding-right-0dot625rem'>
                        <FormattedMessage defaultMessage='Total'/>
                    </p>
                    {/* <div className='display-flex'> */}
                        {/* <div>
                            <Popover {...{content: <PopoverContent {...{orderAmount: amount, serviceFee, total}}/>, size: 'md', position: {bottom: '2rem', left: '-5.3125rem'}, arrow: 'bottom-middle'}}>
                                <InformationCircle className='width-1dot25rem padding-top-0dot25rem information-circle-popover margin-right-0dot5rem'/>
                            </Popover>
                        </div> */}
                        <p className='text-lg semibold color-gray-700'>
                            <span>{splittedTotal.whole}.</span>
                            <span className='text-sm bold'>{splittedTotal.fraction}</span>
                            {' '}
                            <FormattedMessage defaultMessage='UAH'/>
                        </p>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
};

export default React.memo(Order);