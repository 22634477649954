import React from 'react';
import classNames from 'classnames';

import CheckCheckbox from '../../assets/icons/check-checkbox.svg';

import '../use-form-input/use-form-input.scss';

// label - string or component
// fieldname - string
// labelPosition - default (mobile - above, desktop - left)
// register - register from useForm()
// required - boolean
// placeholder - string


const UseFormCheckbox = ({label, register, fieldname}) => {

    return (
        <div className='form-row display-flex align-items-center'>
            <div className={classNames('form-row__label', 'color-gray-900', 'text-md', `form-row__label--checkout`)}>{label}</div>
            <label htmlFor={fieldname}>
                <input
                    className='ogle-checkbox-input'
                    type="checkbox"
                    id={fieldname}
                    {...register(fieldname)}
                />
                <div className='ogle-checkbox'><CheckCheckbox className='display-block color-white' /></div>
            </label>
        </div>
    )
};

export default React.memo(UseFormCheckbox);