import React from 'react';
import {FormattedMessage} from 'react-intl';
import {CartStateContext, CartDispatchContext} from '../../../components/CartContext';
import ImpulsePage from '../impulse/Impulse';

const TicketingImpulsePage = () => {
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const subtitle = <FormattedMessage defaultMessage='To reserve your order for 15 min. please enter your contact details and click the "Proceed with ordering" button'/>;
    const message = <FormattedMessage defaultMessage='The order will be sent to the specified email'/>;
    const showOrderItemQuantity = true;
    
    return (
        <ImpulsePage {...{state, dispatch, subtitle, message, showOrderItemQuantity}}/>
    )
};

export default React.memo(TicketingImpulsePage);
