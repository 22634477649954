import React from "react";
import {FormattedMessage} from 'react-intl';
import PaymentPage from '../payment/Payment';


const VendingPaymentPage = () => {
    const successVerticalPath = 'vending';
    const orderMessage = <FormattedMessage defaultMessage='A payment check will be sent to the specified e-mail address'/>;

    return (
        <PaymentPage {...{successVerticalPath, orderMessage}}/>
    )
};

export default React.memo(VendingPaymentPage);