import React from 'react';
import {useParams} from 'react-router-dom';
// import ErrorBoundary from '../../ErrorBoundary';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import A from './a/A';
import B from './b/B';
import C from './c/C';
import D from './d/D';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallbackHomePage from '../../ErrorFallbackHomePage';
import Skeleton from '../../Skeleton';

const components = {
    'A': A,
    'B': B,
    'C': C,
    'D': D
};

const Warp = React.memo(() => {
    const {payload} = useParams();
    const data = useLazyLoadQuery(
        graphql`
            query WarpServiceQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    warp
                }
            }
        `,
        {payload: decodeURIComponent(payload)}
    );
    // https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
    const SpecificWarp = components[data.serviceByIdOrSlug.warp] || A;
    return (
        <SpecificWarp id={data.serviceByIdOrSlug.id}/>
    );
});

export default React.memo(() =>
    <ErrorBoundary {...{FallbackComponent: ErrorFallbackHomePage}}>
        <React.Suspense fallback={'Loading...'}>
            <Warp/>
        </React.Suspense>
    </ErrorBoundary>
);