import React from 'react';
import {CheckoutStateContext, CheckoutDispatchContext} from './CheckoutContext';
import {v4} from 'uuid';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            const newState = [{key: v4(), ...action.payload}];
            if (state.length > 3) {
                newState.push(...state.slice(0, 3))
            } else {
                newState.push(...state);
            }
            return newState;
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const checkout__ = 'checkout__1';
const deserialize = () => window.localStorage.getItem(checkout__) && JSON.parse(window.localStorage.getItem(checkout__)) || [];
const serialize = (checkout) => window.localStorage.setItem(checkout__, JSON.stringify(checkout));

export default React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <CheckoutStateContext.Provider value={state}>
            <CheckoutDispatchContext.Provider value={dispatch}>
                {children}
            </CheckoutDispatchContext.Provider>
        </CheckoutStateContext.Provider>
    );
});