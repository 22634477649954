import React from 'react';
import './Skeleton.scss';

function Skeleton() {
    return (
        <div className='skeleton'>
            <div className='skeleton__title'>&nbsp;</div>
            <ul className='skeleton__paragraph'>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
            </ul>
        </div>
    );
}

export default React.memo(Skeleton);