import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';
import searchFilter from '../../../searchFilter';
import {RouterLinkButton} from '../../../components/button/button';
import DoneIcon from '../../../assets/icons/done.svg';
import LogoDarkName from '../../../assets/logo-dark-name.svg';

const SuccessPage = ({title, message}) => {
    const {payload} = useParams();
    const location = useLocation();
    return (
        <div className='checkout-content block'>
            <div className='padding-top-4rem mw768-padding-top-14rem checkout-view display-flex flex-direction-column height-100vh'>
                <div className='flex-1'>
                    <div className='text-align-center'>
                        <DoneIcon/>
                    </div>
                    <div className='text-xl color-gray-700 semibold text-align-center'>
                        {title}
                    </div>
                    <div className='text-lg color-gray-500 padding-bottom-1dot5rem text-align-center'>
                        {message}
                    </div>
                    <div className='display-flex mw768-justify-content-center'>
                        <div className='mxw767-flex-1 margin-right-0dot75rem'>
                            <RouterLinkButton {...{color: 'secondary-gray', size: 'xl', mw768Size: 'xl-wide', fluid: 'mobile', to: `/${payload}${searchFilter(location.search)}`}} >
                                <FormattedMessage defaultMessage='To main page'/>
                            </RouterLinkButton>
                        </div>
                    </div>
                </div>
                <div className='display-flex align-items-center justify-content-center padding-top-2rem padding-bottom-1dot5rem'>
                    <div className='margin-right-0dot25rem color-gray-500 medium text-md'><FormattedMessage defaultMessage='Powered by'/></div>
                    <div><LogoDarkName className='display-block'/></div>
                </div>
            </div>
        </div>
    )
};

export default React.memo(SuccessPage);