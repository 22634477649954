import React from 'react';

export const FetchKeyStateContext = React.createContext();
export const FetchKeySetStateContext = React.createContext(() => {});

export const FetchKeyProvider = React.memo(({children}) => {
    const [fetchKey, setFetchKey] = React.useState(0);
    return (
        <FetchKeyStateContext.Provider value={fetchKey}>
            <FetchKeySetStateContext.Provider value={setFetchKey}>
                {children}
            </FetchKeySetStateContext.Provider>
        </FetchKeyStateContext.Provider>
    );
});

export const useFetchKey = () => React.useContext(FetchKeyStateContext);
export const useFetchKeySetState = () => React.useContext(FetchKeySetStateContext);