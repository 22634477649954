import React from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import '../use-form-input/use-form-input.scss';

// label - string or component
// fieldname - string
// labelPosition - default (mobile - above, desktop - left)
// register - register from useForm()
// errors - errors object from formState for fieldname
// required - boolean
// placeholder - string

const UseFormTextarea = ({label, labelPosition, register, errors, fieldname, registerOptions, placeholder, disabled, valid, readonly, rows = 4}) => {
    const required = registerOptions && registerOptions.required;

    return (
        <div className='form-row'>
            <div className={classNames('form-row__label', 'medium', 'color-gray-700', 'text-sm', {'form-row__label--required': required}, `form-row__label--${labelPosition || 'default'}`)}>{label}</div>
            <div className='form-row__input-container'>
                <textarea
                    rows={rows}
                    placeholder={placeholder}
                    className={classNames('form-row__input', {'form-row__input--error': errors, 'form-row__input--readonly': readonly})}
                    {...register(fieldname, registerOptions)}
                    disabled={disabled}
                    readOnly={readonly}
                />
                {errors && errors.type === 'required' && <div className='form-row__error text-sm color-error-600'><FormattedMessage defaultMessage='This field is required'/></div>}
                {errors && errors.type === 'pattern' && <div className='form-row__error text-sm color-error-600'><FormattedMessage defaultMessage='This field is not valid'/></div>}
            </div>
        </div>
    )
};

export default React.memo(UseFormTextarea);