import React from 'react'
import './A.scss';
import {Link, useLocation, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {CartStateContext, CartDispatchContext} from '../../../CartContext';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from './../../../Marked';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import useNow from '../../../../useNow';
import {adopt} from '../../../../math';
import {IN, ENTITY_TYPE} from '../../../../constants';
import Mini from './../../mini/Mini';
import IconMaster from '../../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../../assets/icons-payment/icon-google-pay.svg';
import {useForm} from 'react-hook-form';
import ErrorFallback from '../../../ErrorFallback';
import Browser from '../../../Browser';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const config = {shouldValidate: true, shouldDirty: true};

const AEndpoint = React.memo(({endpoint, serviceById}) => {
    const {payload} = useParams();
    const location = useLocation();
    const {locale = 'uk', more} = useQuery();
    const navigate = useNavigate();
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment AEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                start
                end
                strategy
                every
                description
                descriptionUk
                untie
                hints
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment AEndpoint_serviceById on Service {
                id
            }
        `,
        serviceById
    );
    const count = React.useMemo(() =>
            state
                .filter(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id)
                .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint.id, fServiceById.id]
    );
    const now = useNow();
    const dpt = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy,
        fEndpoint.every), [now, fEndpoint]);
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const cart = data => {
        !count && dispatch({type: 'add', payload: {
            endpointId: fEndpoint.id,
            serviceId: fServiceById.id,
            ...(fEndpoint.untie && {untie: {price: data.input, currency: 'uah'}}) 
        }});
        navigate(`/${payload}/checkout-new/cart${searchFilter(location.search)}`);
    };
    return (
        <div className='margin-top-1rem'>
            <div className='font-weight-bold'>
                {i18n(fEndpoint, 'name', locale)} {fEndpoint.price} UAH{fEndpoint.description ? ` ${i18n(fEndpoint, 'description', locale)}` : ''}
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{fEndpoint.id}</div>}
            <Mini {...{
                start: fEndpoint.start,
                end: fEndpoint.end,
                strategy: fEndpoint.strategy,
                every: fEndpoint.every,
                entityType: ENTITY_TYPE.endpoint
            }}/>
            {dpt.state === IN &&
            <>
            {(fEndpoint.quantity < 16 || isTrue(more)) && <div className='input-warning'>{fEndpoint.quantity} <FormattedMessage defaultMessage='left'/></div>}
            <form className='form margin-top-1rem'>
                <Browser>
                    {!count && fEndpoint.untie && 
                        <>
                            <div className='input-label'><FormattedMessage defaultMessage='Amount'/> UAH <sup>*</sup></div>
                            <div className='input'>
                                <input
                                    className='width-100percent'
                                    type='number'
                                    step='any'
                                    placeholder='0.00'
                                    {...register('input', {required: true, pattern: /^\d+(?:[.]\d{1,2})*$/})}
                                />
                            </div>
                            {errors.input && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                            {fEndpoint.hints &&
                            <div className='display-flex flex-wrap-wrap'>
                            {fEndpoint.hints.map((item, index) =>
                                <div
                                    key={index}
                                    className='button margin-right-0dot5rem margin-bottom-0dot5rem'
                                    onClick={() => setValue('input', item, config)}
                                >{item}</div>
                            )}
                            </div>
                            }
                        </>
                    }
                    <div className='display-flex justify-content-center'>
                        <div
                            style={{backgroundColor: '#d0d94d', color: 'black'}}
                            className='button primary'
                            onClick={handleSubmit(cart)}
                        >
                            {count ? <FormattedMessage defaultMessage='Already in the cart, go'/> : <FormattedMessage defaultMessage='Buy'/>}
                        </div>
                    </div>
                </Browser>
            </form>
            </>
            }
        </div>
    );
});

const AService = ({id}) => {
    const data = useLazyLoadQuery(
        graphql`
            query AQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    start
                    end
                    strategy
                    every
                    endpoints {
                        id
                        quantity
                        ...AEndpoint_endpoint
                    }
                    ...AEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    const showTicketsBoxBtn = data.serviceById.description && data.serviceById.description.includes('btnAnchorTickets');
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }
    const {locale = 'uk', more} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceById.start, data.serviceById.end, data.serviceById.strategy,
        data.serviceById.every), [now, data.serviceById]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceById, 'name', locale)}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            {showTicketsBoxBtn &&
                <div className='position-absolute top-1dot5rem right-1rem'>
                    <div className='button primary' onClick={() => scrollToTickets()}><FormattedMessage defaultMessage='Tickets'/></div>
                </div>
            }
            <div className='view'>
                <div className='block'>
                    <div className='font-size-2rem font-weight-bold text-align-center margin-bottom-1rem'>
                        {i18n(data.serviceById, 'name', locale)}
                    </div>
                    <Marked {...{content: i18n(data.serviceById, 'details', locale)}}/>
                    <div className="display-flex flex-wrap-wrap">
                        <div className="flex-grow-1 flex-basis-300px margin-bottom-1rem">
                            {i18n(data.serviceById, 'details', locale) && i18n(data.serviceById, 'details', locale).endsWith('!') ?
                                <>
                                    <p><FormattedMessage defaultMessage='Sales were closed'/></p>
                                </>
                                :
                                <>
                                <Mini {...{
                                    start: data.serviceById.start,
                                    end: data.serviceById.end,
                                    strategy: data.serviceById.strategy,
                                    every: data.serviceById.every,
                                    entityType: ENTITY_TYPE.service
                                }}/>
                                {state === IN &&
                                <div ref={ticketsBoxRef}>
                                {data.serviceById.endpoints.map((endpoint, index) =>
                                <div key={endpoint.id}>
                                    {(more || data.serviceById.endpoints[index].quantity > 0) && <AEndpoint endpoint={endpoint} serviceById={data.serviceById}/>
                                    }
                                </div>
                                )}
                                {!data.serviceById.endpoints.some(e => e.quantity) && <div>Квитки закінчилися</div>}
                                </div>
                                }
                                </>
                            }
                        </div>
                    </div>
                    <br/>
                    <p className='font-size-0dot8rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>
                </div>
            </div>
        </div>
        </>
    )
};

const A = ({id}) => {
    const {payload} = useParams();
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='a black'>
            <div className='a__header'>
                <Link to={`/${payload}${searchFilter(location.search)}`}><span className='a__accent font-weight-bold'>Tickets</span> by Ottry</Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <AService {...{id}}/>
                </React.Suspense>
            </ErrorBoundary>
            <div className='a__footer'>
                <div className='a__footer-line'>
                    <div className='a__footer-line-left'>&nbsp;</div>
                    <Link to={`/${payload}${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='a__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                    >En</a>
                </div>
                <div className='a__payment margin-bottom-1rem'>
                    <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                    <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                </div>
                <div className='a__footer-content'>
                    <div className='a__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <a href={legal + `/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a>
                        <a href={legal + `/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a>
                        <a href={legal + `/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                    </div>
                </div>
                <div className='a__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default React.memo(A);